html {
  min-height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item > a:not(.ui) {
  text-decoration: none;
  color: #000 !important;
}

.ui.menu .item:before {
  width: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.dropdown > .dropdown.icon:before {
  color: white;
}

.ui.card,
.ui.card:first-child {
  margin: 0 !important;
  height: 222px;
  margin-top: 8px !important;
}

.ui.card.horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  max-width: 535px;
}

.ui.card > .content > .header + .description,
.ui.card > .content > .meta + .description,
.ui.cards > .card > .content > .header + .description,
.ui.cards > .card > .content > .meta + .description {
  margin-top: 0.5em;
  height: -webkit-fill-available;
}

.ui.card > .content,
.ui.cards > .card > .content {
  padding: 15px 16px 20px;
  display: flex;
  flex-direction: column;
}

.ui.card.horizontal > .image {
  width: 256px;
  height: 100% !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.card > .content > .card-header-title,
.ui.cards > .card > .content > .card-header-title {
  color: #004ea0 !important;
}

.field > .ui.fluid.selection.dropdown > .dropdown.icon:before {
  color: #c3c3c3;
}

form > .error > .ui.fluid.input > input,
form > .error > .ui.fluid.selection.dropdown,
form
  > .grouped.fields
  > .disabled.error.required.field
  > .ui.disabled.fluid.icon.input
  > input {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}

form > .error > .ui.pointing.above.prompt.label,
form
  > .grouped.fields
  > .disabled.error.required.field
  > .ui.pointing.above.prompt.label {
  background-color: #fff6f6;
  color: #9f3a38;
  border: 0 solid #e0b4b4;
  border-radius: 0.28571429rem;
}

.bannerPdf {
  height: 142px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

#container__headerAndBanner {
  display: flex;
  flex-direction: column;
}

.title {
  color: #004ea0 !important;
  font-weight: bold;
  /* text-transform: capitalize; */
}

@media (max-width: 1200px) {
  .ui.card,
  .ui.card:first-child {
    height: auto;
  }
}

@media (max-width: 440px) {
  .ui.container {
    width: 59%;
  }

  #root {
    zoom: inherit;
  }

  .ui.card,
  .ui.card:first-child {
    height: auto;
  }
}

.ui.card > :first-child,
.ui.cards > .card > :first-child {
  border-radius: 0.285714rem 0.285714rem 0.285714rem 0.285714rem;
  border-top: none !important;
}

.ui.inverted.segment {
  background: #004ea0 !important;
}

.ui.menu {
  margin: 0;
}

.ui.card:last-child {
  margin-bottom: 10px;
}

.ui.menu .ui.dropdown .menu > .selected.item {
  display: flex;
  font-weight: unset !important;
}

.ui.menu .ui.dropdown .menu > .active.selected.item > .text > a > .peopleName {
  font-weight: bolder !important;
}

.ui.menu .ui.dropdown .menu > .item {
  display: flex;
}

#disabledLink {
  display: none;
}

@media (max-width: 768px) {
  .ui.container {
    width: 59%;
  }

  .ui.button.unauthorized {
    background-color: #232323 !important;
    color: #fff !important;
    font-size: 11px !important;
  }

  .ui.button:hover {
    background-color: #f0cc00 !important;
    color: #000 !important;
    font-size: 12px !important;
  }

  .ui.button {
    background-color: #feda00 !important;
    color: #000 !important;
    font-size: 12px !important;
    transition: background-color 0.2s;
  }

  .ui.card,
  .ui.card:first-child {
    height: 239px !important;
  }
}

.language-item {
  margin-left: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  background: #003771 !important;
  font-weight: bold;
  color: #75a7db !important;
}

.language-item.active {
  background: #feda00 !important;
  color: #004ea0 !important;
}

.flex-item {
  flex: 40%;
}

@media (max-width: 1200px) {
  .flex-item {
    flex: 100%;
  }
}

.ui.button {
  background-color: #feda00 !important;
  color: #000 !important;
  transition: background-color 0.2s;
}

.ui.button:hover {
  background-color: #f0cc00 !important;
  color: #000 !important;
}

.ui.button.unauthorized {
  background-color: #232323 !important;
  color: #fff !important;
}

.ui.button.unauthorized:hover {
  background-color: #333 !important;
  color: #fff !important;
}

.ui.button.unauthorized .icon {
  color: #feda00 !important;
}

.selectModal {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 2px solid black;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

@media (max-width: 900px) {
  .ui.top.left.pointing.dropdown > .menu {
    left: -105px !important;
  }

  .title {
    margin-top: 1em !important;
  }

  .ui.card.horizontal {
    flex-direction: column !important;
    margin-bottom: 250px !important;
  }

  .ui.card.horizontal > .image {
    width: 100%;
    height: 320px !important;
  }

  i.big.icon, i.big.icons {
    font-size: 2em !important;
  }
}

@media (max-width: 400px) {
  .ui.card.horizontal {
    max-width: 100%;
    margin-bottom: 250px !important;
  }

  .ui.card.horizontal > .image {
    height: 300px !important;
  }

  i.big.icon, i.big.icons {
    font-size: 1.6em !important;
  }
}